import React, { useState, useEffect } from "react";
import css from "../css/tournament.module.css";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";

const Tournament = () => {
  const [activeTab, setActiveTab] = useState("winnerPrizes");
  const [tournamentData, setTournamentData] = useState([]);
  const [winnerPrizes, setWinnerPrizes] = useState({});
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [enteyFee, setEnteyFee] = useState({});
  const [tournamentIds, setTournamentId] = useState(null);
  const [pairJoin, setPairJoin] = useState([]);
  const [userId, setUserId] = useState(null);
  const [pairedlistID, setpairedlistID] = useState(null);
  const [playerid, setPlayerid] = useState([]);

  const [currentPage, setCurrentPage] = useState(1); // State to track current page
  const tournamentsPerPage = 5; // Number of tournaments per page
  const totalTournaments = tournamentData.length;
  const totalPages = Math.ceil(totalTournaments / tournamentsPerPage);

  // Calculate the index of the first and last tournament to display
  const indexOfLastTournament = currentPage * tournamentsPerPage;
  const indexOfFirstTournament = indexOfLastTournament - tournamentsPerPage;
  const currentTournaments = tournamentData.slice(indexOfFirstTournament, indexOfLastTournament);

  // Change page handler
  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  const baseUrl = process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BACKEND_LOCAL_API
    : process.env.REACT_APP_BACKEND_LIVE_API;

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUserId(res.data._id)
      })
      .catch((e) => {
        console.log("Error");
      });
  };


  useEffect(() => {
    role();
  })


  useEffect(() => {
    // Fetch tournament data when the component loads
    fetchTournamentData();
  }, []);

  const fetchTournamentData = async () => {
    try {
      const access_token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${access_token}` };
      const response = await axios.get(`${baseUrl}tournament-list`, { headers });


      const tournaments = response.data.TournamentModeldata || [];
      if (tournaments.length > 0) {
        setTournamentData(tournaments);
        setEnteyFee(tournaments[0].entry_fees);
        setTournamentId(tournaments[0].t_id);
        setWinnerPrizes(response.data.TournamentWinnerPrizedata[0]);
      } else {
        console.warn("No tournaments available.");
      }
    } catch (error) {
      console.error("Error fetching tournament data:", error);
    }
  };

  const TournamentPairJoin = async () => {

    if (!tournamentIds) {
      console.warn("Tournament ID is not set. Skipping TournamentPairJoin.");
      return;
    }

    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    try {
      const res = await axios.post(
        `${baseUrl}tournament-pair-and-join-list`,
        { tournament_id: tournamentIds },
        { headers }
      );
      // setpairedlistID(res.data.pairedlist[0]._id);
      setPairJoin(res.data.pairedlist);
    } catch (e) {
      console.error("Error in TournamentPairJoin:", e);
    }
  };


  useEffect(() => {
    TournamentPairJoin();
  }, [tournamentIds]);

  const handleJoinNow = (tournamentId) => {
    console.log(`Joining tournament: ${tournamentId}`);
    axios
      .post(
        `${baseUrl}/tournament-join`,
        { tournament_id: tournamentId, Game_type: "tournament", Game_Ammount: enteyFee },
        { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
      )
      .then((res) => {
        Swal.fire({
          title: res.data.msg,
          icon: res.data.status ? "success" : "warning",
          confirmButtonText: "OK",
        });
      })
      .catch((error) => {
        console.error(error);
        alert("Failed to join the tournament.");
      });
  };

  const fetchLeaderboardData = async (tournamentId) => {
    try {
      const access_token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${access_token}` };

      const response = await axios.post(
        `${baseUrl}/tournament-join-player-list`,
        { tournament_id: tournamentId },
        { headers }
      );
      setLeaderboardData(response.data.TournamentModeldata || []);
    } catch (error) {
      console.error("Error fetching leaderboard data:", error);
    }
  };



  const isUserPaired = pairJoin.some(
    (pair) => pair.creater_userid === userId || pair.joiner_userid === userId
  );
  useEffect(() => {
    const userPair = pairJoin.find(
      (pair) => pair.creater_userid === userId || pair.joiner_userid === userId
    );

    if (userPair && userPair._id !== playerid) {
      setPlayerid(userPair._id);
    }
  }, [userId, pairJoin, playerid]);

  return (
    <div className="leftContainer" style={{ minHeight: "100vh" }}>
      <div
        className={css.mainArea}
        style={{ paddingTop: "60px", minHeight: "100vh" }}
      >
        <div className={css.tournamentContainer}>
          {tournamentData.map((tournament, index) => (
            <div key={index} className={css.tournament}>
              <header className={`d-flex justify-content-between ${css.tournamentHeader}`}>
                <div className={css.infoContainer}>
                  <div className={css.infoBox}>
                    <p className="mb-0">Start Time</p>
                    <span>{tournament.start_time}</span>
                  </div>
                </div>
                <div className={css.infoBox}>
                  <p className="mb-0">End Time</p>
                  <span>{tournament.end_time}</span>
                </div>
                <div className={css.infoBox}>
                  <p className="mb-0">Status</p>
                  <span>{tournament.status === 1 ? "Active" : "Completed"}</span>
                </div>
              </header>

              {isUserPaired ? (
                <Link
                  to={{ pathname: `/tourmentresult/${playerid}`, }} style={{ bottom: "0" }}>
                  <button
                    className={`d-flex w-100 mt-2 text-center justify-content-center ${css.joinNowButton}`}
                  >
                    Play
                  </button>
                </Link>
              ) : (
                <button
                  className={`d-flex w-100 mt-2 text-center justify-content-center ${css.joinNowButton}`}
                  onClick={() => handleJoinNow(tournament.t_id)}
                >
                  Join Now ₹{tournament.entry_fees}
                </button>
              )}

              <div className='winner_tab'>
                <button
                  className={`${css.tabButton} ${activeTab === "winnerPrizes" ? css.activeTab : ""}`}
                  onClick={() => setActiveTab("winnerPrizes")}
                >
                  Winner Prizes
                </button>
                <button
                  className={`${css.tabButton} ${activeTab === "leaderboard" ? css.activeTab : ""}`}
                  onClick={() => {
                    setActiveTab("leaderboard");
                    fetchLeaderboardData(tournament.t_id); // Fetch leaderboard data when the tab is selected
                  }}
                >
                  Leaderboard
                </button>
              </div>
              {activeTab === "winnerPrizes" && (
                <div className="">
                  <div className="w-100 mt-2">
                    <div>
                     <div className="d-flex justify-content-between">
                     <div className="contentboard">
                        Rank
                      </div>
                      <div className="contentboard">Winning</div>
                     </div>
                      {Object.entries(winnerPrizes).map(([key, value], idx) =>
                        key.startsWith("winnerPrize") ? (
                          <div key={idx} className="d-flex justify-content-between pricerank">
                            <div className="bgimage_new prize text-dark ">
                              {key.replace("winnerPrize", "")}
                            </div>
                            <div className="prize text-dark  padding_15 text-right">
                              <span>₹{value}</span>
                            </div>
                          </div>
                        ) : null
                      )}
                    </div>
                  </div>
                </div>
              )}

              {activeTab === "leaderboard" && (
                <div className="">
                <div className="d-flex justify-content-between leaderboard leaderboardesign mt-2">
                  <div className="">
                    <p className="text-center font-design mb-0">User Name</p>
                    {leaderboardData.length > 0 ? (
                      <ul className="unstyled text-center pl-0">
                        {leaderboardData.map((entry, idx) => (
                          <li key={idx}>
                            {entry.username}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>No leaderboard data available.</p>
                    )}
                  </div>
                  <div className="">
                    <p className="text-center font-design mb-0">Results</p>
                    {leaderboardData.length > 0 ? (
                      <ul className="unstyled text-center pl-0">
                        {leaderboardData.map((entry, idx) => (
                          <li
                            key={idx}
                            style={{
                              color: entry.result ? "orange" : "green",
                            }}
                          >
                            {entry.result ? "awaiting" : "success"}
                          </li>
                        ))}
                      </ul>

                    ) : (
                      <p>No leaderboard data available.</p>
                    )}
                  </div>
                  </div>
                  <div className="col-12 text-center">
                    {/* Pagination controls */}
                    <div className="pagination-controls d-flex justify-content-center">
                      <button
                        onClick={() => paginate(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="pagination-button"
                      >
                        <RiArrowLeftSLine />
                      </button>
                      {[...Array(totalPages)].map((_, idx) => (
                        <button
                          key={idx}
                          onClick={() => paginate(idx + 1)}
                          className={`pagination-button ${currentPage === idx + 1 ? 'active' : ''}`}
                        >
                          {idx + 1}
                        </button>
                      ))}
                      <button
                        onClick={() => paginate(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="pagination-button"
                      >
                        <RiArrowRightSLine />
                      </button>
                    </div>
                  </div>
                </div>
              )}

            </div>

          ))}

          <div className="table-responsive bg-light rounded creator_table">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th className="d-flex justify-content-center">Creator</th>
                  <th></th>
                  <th className="d-flex justify-content-center">Accepter</th>
                </tr>
              </thead>
              <tbody>
                {pairJoin && pairJoin.map((game, key) => (
                  <tr key={key}>
                    <td>{key + 1}</td>
                    <td>
                      <span className="pl-2">{game.creater_name} / {game.creater_phone}</span>
                    </td>
                    <td> <img
                      src={
                        process.env.PUBLIC_URL + "/Images/Homepage/versus.png"
                      }
                      alt=""
                      width="21px"
                    /></td>
                    <td>
                      <span className="pl-2">{game.joiner_name} / {game.joiner_phone}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination-controls mb-2">
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <RiArrowLeftSLine />
              </button>
              {[...Array(totalPages)].map((_, idx) => (
                <button
                  key={idx}
                  onClick={() => paginate(idx + 1)}
                  className={currentPage === idx + 1 ? 'active' : ''}
                >
                  {idx + 1}
                </button>
              ))}
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <RiArrowRightSLine />
              </button>
            </div>
          </div>
        </div>




      </div>
    </div>
  );
};

export default Tournament;

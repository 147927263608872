import React, { memo } from "react";
import axios from "axios";
import css from "../Modulecss/Home.module.css";

const BoatCard = memo(({ boatChallenge, onPlay }) => {

  return (
    <div className="">
      <div>
        <div className="">
          <div className={`${css.betCard} mt-2`}>
            <span className={`${css.betCardTitle} pl-3 d-flex align-items-center text-uppercase ${css.betTitleDiv}`}>
              CHALLENGE FROM
              <span className="ml-1 challanger">{boatChallenge.creater}</span>
            </span>
            <div className={`d-flex justify-content-between align-items-center px-2 ${css.betBodyDiv}`}>
              <div className="pr-3 pb-1">
                <span className={css.betCardSubTitle}>Entry Fee</span>
                <div>
                  <img src="/Images/rupee.png" alt="" width="21px" />
                  <span className={css.betCardAmount}>{boatChallenge.amount}</span>
                </div>
              </div>
              <div className="position-relative">
                <button
                 className={`${css.bgSecondary} ${css.playButton} ${css.cxy}`}
                  onClick={() => onPlay(boatChallenge._id)}
                >
                  Play
                </button>
              </div>
              <div>
                <span className={css.betCardSubTitle}>Prize</span>
                <div>
                  <img src="/Images/LandingPage_img/global-rupeeIcon.png" alt="" width="21px" />
                  <span className={css.betCardAmount}>{boatChallenge.prize}</span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="position-relative lokesh">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default BoatCard;

import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import Header from "../Components/Header";
import "../css/viewGame1.css";
import "../css/layout.css";
import css from "../css/Pan.module.css";
import Swal from "sweetalert2";

export default function TournamentResult() {
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname.split("/")[2];

  // Environment variables
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  // State variables
  const [gameDetails, setGameDetails] = useState(null);
  const [status, setStatus] = useState(null);
  const [screenshot, setScreenshot] = useState(null);
  const [preview, setPreview] = useState(null);
  const [userAllData, setUserAllData] = useState(null);
  const [user, setUser] = useState();
  const [roomcode, setRoomcode] = useState("");
  const [fecthStatus, setFecthStatus] = useState();
  const submitReq = useRef(false);

  // Fetch user details and game details
  
  const fetchUserDetails = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      history.push("/login");
      return;
    }
  
    try {
      const headers = { Authorization: `Bearer ${token}` };
      const { data } = await axios.get(`${baseUrl}me`, { headers });
      setUser(data._id); 
      setUserAllData(data); 
    } catch (error) {
      if (error.response?.status === 401) {
        localStorage.removeItem("token");
        history.push("/login");
      } else {
        console.error("An error occurred while fetching user details:", error);
      }
    }
  };
  


  const getPost = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    if (roomcode.length !== 8 || !/^\d{8}$/.test(roomcode)) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Room Code must be 8 digits long",
        showConfirmButton: false,
        timer: 1200,
      });
      return;
    }

    let runnext = true;

    if (runnext) {
      await axios
        .post(
          baseUrl + `tournament-room-code-update`,
          {
            Room_code: roomcode,
            id:path,
          },
          { headers }
        )
        .then((res) => {
          if (res.data.status == "error") {
            Swal.fire({
              position: "center",
              icon: "error",
              type: "error",
              title: "Sorry 😫",
              showConfirmButton: false,
              timer: 1200,
            });
          } else {
            console.log("somthing not found")
            // setGame(res.data);
            // socket.emit("challengeOngoing");
          }
        })
        .catch((e) => {
          if (e.response.status == 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("token");
            window.location.reload();
            history.push("/login");
          }
        });
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        type: "error",
        title: "Room Code is not classic",
        showConfirmButton: false,
        timer: 1200,
      });
    }
  };


  
  const fetchGameDetails = async () => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
  
    try {
      const { data } = await axios.post(
        `${baseUrl}tournament-lobby-list`,
        { id: path },
        { headers }
      );
  
      const gameDetails = data.pairedlist?.[0] || null;
      setGameDetails(gameDetails);

      if (user === gameDetails.joiner_userid) {
        setFecthStatus(gameDetails.Joiner_Status);
      }

      if (user === gameDetails.creater_userid) {
        setFecthStatus(gameDetails.Creator_Status);
      }

    } catch (error) {
      if (error.response?.status === 401) {
        localStorage.removeItem("token");
        history.push("/login");
      }
    }
  };

  useEffect(() => {
    const initialize = async () => {
      await fetchUserDetails();
      if (user) {
        fetchGameDetails();
      }
    };
    initialize();
  }, [user]);
  

  // Handle file input changes
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setScreenshot(file);
      setPreview(URL.createObjectURL(file));
    }
  };

  const clearScreenshot = () => {
    setScreenshot(null);
    setPreview(null);
  };

  // Submit result
  const submitResult = async (e) => {
    e.preventDefault();
    if (!status) {
      alert("Please select a status.");
      return;
    }
    if (submitReq.current) return;

    submitReq.current = true;
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    const formData = new FormData();

    formData.append("id", gameDetails?._id);
    formData.append("status", status);
    // formData.append(
    //   "user_role",
    //   gameDetails?.creater_userid === userAllData?._id ? "creator" : "joiner"
    // );
    if (screenshot) formData.append("file", screenshot);

    try {
      const { data } = await axios.post(
        `${baseUrl}challange/result-tournament`,
        formData,
        { headers }
      );
      alert("Result submitted successfully!");
      history.push("/");
    } catch (error) {
      alert("Error submitting result. Please try again.");
    } finally {
      submitReq.current = false;
    }
  };


  const copyCode = (e) => {
    navigator.clipboard.writeText(gameDetails.Room_code);

    Swal.fire({
      position: "center",
      icon: "success",
      type: "success",
      title: "Room Code Copied",
      showConfirmButton: false,
      timer: 1200,
    });
  };

  return (
    <>
      <Header user={userAllData} />
      {gameDetails && (
       
        <div className="leftContainer">
          <div className="main-area" style={{ paddingTop: "60px" }}>
            <div className="battleCard-bg">
              <div className="battleCard">
                <div className="players cxy pt-2">
                  <div className="flex-column cxy">
                    <h5>{gameDetails?.creater_name}</h5>
                  </div>
                  <img
                    className="mx-3"
                    src="/Images/Homepage/versus.png"
                    width="23px"
                    alt="vs"
                  />
                  <div className="flex-column cxy">
                    <h5>{gameDetails?.joiner_name}</h5>
                  </div>
                </div>

                <div className="amount cxy mt-2">
                  <span style={{ opacity: "0.8" }}>Playing for</span>
                  <img
                    className="mx-1"
                    src="/Images/LandingPage_img/global-rupeeIcon.png"
                    width="25px"
                    alt="Rupee"
                  />
                  <span
                    style={{
                      fontSize: "1.2em",
                      fontWeight: 700,
                      opacity: "0.8",
                    }}
                  >
                    {gameDetails?.amount || "0"}
                  </span>
                </div>
                <div className="thin-divider-x my-3" />
                {(gameDetails.roomcode == 0 && (
                    <div className="roomCode cxy flex-column">
                      Waiting for Room Code...
                      <h6>रूम कोड का इंतजार है।</h6>
                      <div className="lds-spinner">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                     
                    </div>
                  )) ||
                    (gameDetails.roomcode != null && (
                      <div className="roomCode cxy flex-column">
                        <div className="text-center">
                          <div>Room Code</div>
                          <span>{gameDetails.roomcode}</span>
                        </div>
                        <button
                          className="bg-green playButton position-static mt-2"
                          onClick={(e) => copyCode(e)}
                        >
                          Copy Code
                        </button>
                      </div>
                    )) ||
                    (gameDetails.roomcode == null &&
                      ((gameDetails.creater_userid == user && (
                        <div className="roomCode cxy flex-column">
                            Set Room Code
                          <h6>लूडो किंग से रूम कोड अपलोड करें</h6>
                          <input
                            type="number"
                            className="form-control mt-1 w-75"
                            style={{
                              backgroundColor: "#e8eeee",
                              border: "1px solid #47a44780",
                            }}
                            value={roomcode}
                            onChange={(e) => setRoomcode(e.target.value)}
                          />
                          <button
                            className="bg-green playButton position-static mt-2"
                            type="button"
                            onClick={() => getPost()}
                          >
                            SET ROOM CODE
                          </button>

                        </div>
                        
                      )) ||
                        (gameDetails.joiner_userid == user && (
                          <div className="roomCode cxy flex-column">
                            Waiting for Room Code
                            <h6>रूम कोड का इंतजार है।</h6>
                            <div className="lds-spinner">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </div>
                        ))))}
                <div className="rules">
                  <span className="cxy mb-1">
                    <u>Game Rules</u>
                  </span>
                  <ol className="list-group list-group-numbered">
                    <li className="list-group-item">Record every game while playing.</li>
                    <li className="list-group-item">
                      For cancellation, video proof is necessary.
                    </li>
                  </ol>
                </div>

                <div className="match-status-border row">
                  <div className="col-6">Match Status</div>
                </div>

                {fecthStatus !== null && fecthStatus !== undefined ? (
                    <p>
                      You have already updated your battle result for{" "}
                      <h6 className="d-inline-block text-uppercase">
                        <b>{fecthStatus}</b>
                      </h6>
                    </p>
                  ) : (
                    <form onSubmit={submitResult} className="result-area mb-5">
                      {/* Radio Buttons for Status */}
                      <div className="MuiFormGroup-root radios radio-group">
                        <label className="MuiFormControlLabel-root">
                          <input
                            className="jss4 mr-2"
                            type="radio"
                            name="status"
                            value="winn"
                            onChange={() => setStatus("winn")}
                          />
                          <span className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">
                            I Won
                          </span>
                        </label>
                        <label className="MuiFormControlLabel-root">
                          <input
                            className="jss4 mr-2"
                            type="radio"
                            name="status"
                            value="lose"
                            onChange={() => setStatus("lose")}
                          />
                          <span className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">
                            I Lost
                          </span>
                        </label>
                      </div>

                      {/* Screenshot Upload Section */}
                      {status && status !== "lose" && (
                        <div className={css.doc_upload}>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            required
                          />
                          <div className="cxy flex-column position-absolute">
                            <i className="fa-solid fa-arrow-up text-dark"></i>
                            <div className={`${css.sideNav_text} mt-2`}>Upload screenshot.</div>
                          </div>
                        </div>
                      )}

                      {/* Preview Section */}
                      {preview && (
                        <div className="mt-2">
                          <img src={preview} alt="Preview" className="img-fluid" />
                          <button type="button" onClick={clearScreenshot}>
                            Clear Screenshot
                          </button>
                        </div>
                      )}

                      {/* Submit Button */}
                      <button type="submit" className="btn btn-danger mt-3 text-white">
                        Submit Result
                      </button>
                    </form>
                  )}

                
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
